const Feature = {
  BLEETER: "BLEETER",
  TOW: "TOW",
  TAXI: "TAXI",
  COURTHOUSE: "COURTHOUSE",
  TRUCK_LOGS: "TRUCK_LOGS",
  AOP: "AOP",
  BUSINESS: "BUSINESS",
  ALLOW_DUPLICATE_CITIZEN_NAMES: "ALLOW_DUPLICATE_CITIZEN_NAMES",
  DISCORD_AUTH: "DISCORD_AUTH",
  CALLS_911: "CALLS_911",
  WEAPON_REGISTRATION: "WEAPON_REGISTRATION",
  SOCIAL_SECURITY_NUMBERS: "SOCIAL_SECURITY_NUMBERS",
  CUSTOM_TEXTFIELD_VALUES: "CUSTOM_TEXTFIELD_VALUES",
  ACTIVE_DISPATCHERS: "ACTIVE_DISPATCHERS",
  ACTIVE_INCIDENTS: "ACTIVE_INCIDENTS",
  ALLOW_CITIZEN_UPDATE_LICENSE: "ALLOW_CITIZEN_UPDATE_LICENSE",
  ALLOW_REGULAR_LOGIN: "ALLOW_REGULAR_LOGIN",
  RADIO_CHANNEL_MANAGEMENT: "RADIO_CHANNEL_MANAGEMENT",
  ALLOW_CITIZEN_DELETION_BY_NON_ADMIN: "ALLOW_CITIZEN_DELETION_BY_NON_ADMIN",
  DMV: "DMV",
  BADGE_NUMBERS: "BADGE_NUMBERS",
  USER_API_TOKENS: "USER_API_TOKENS",
  CITIZEN_RECORD_APPROVAL: "CITIZEN_RECORD_APPROVAL",
  COMMON_CITIZEN_CARDS: "COMMON_CITIZEN_CARDS",
  STEAM_OAUTH: "STEAM_OAUTH",
  CREATE_USER_CITIZEN_LEO: "CREATE_USER_CITIZEN_LEO",
  LEO_TICKETS: "LEO_TICKETS",
  LEO_BAIL: "LEO_BAIL",
  COURTHOUSE_POSTS: "COURTHOUSE_POSTS",
  ACTIVE_WARRANTS: "ACTIVE_WARRANTS",
  CITIZEN_DELETE_ON_DEAD: "CITIZEN_DELETE_ON_DEAD",
  PANIC_BUTTON: "PANIC_BUTTON",
  WARRANT_STATUS_APPROVAL: "WARRANT_STATUS_APPROVAL",
  DIVISIONS: "DIVISIONS",
  TONES: "TONES",
  LICENSE_EXAMS: "LICENSE_EXAMS",
  CITIZEN_CREATION_RECORDS: "CITIZEN_CREATION_RECORDS",
  BUREAU_OF_FIREARMS: "BUREAU_OF_FIREARMS",
  CALL_911_APPROVAL: "CALL_911_APPROVAL",
  FORCE_DISCORD_AUTH: "FORCE_DISCORD_AUTH",
  FORCE_STEAM_AUTH: "FORCE_STEAM_AUTH"
};
const JailTimeScale = {
  HOURS: "HOURS",
  MINUTES: "MINUTES",
  SECONDS: "SECONDS"
};
const DiscordWebhookType = {
  CALL_911: "CALL_911",
  PANIC_BUTTON: "PANIC_BUTTON",
  UNIT_STATUS: "UNIT_STATUS",
  BOLO: "BOLO",
  CITIZEN_RECORD: "CITIZEN_RECORD",
  VEHICLE_IMPOUNDED: "VEHICLE_IMPOUNDED",
  WARRANTS: "WARRANTS"
};
const Rank = {
  OWNER: "OWNER",
  ADMIN: "ADMIN",
  USER: "USER"
};
const WhitelistStatus = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  DECLINED: "DECLINED"
};
const StatusViewMode = {
  FULL_ROW_COLOR: "FULL_ROW_COLOR",
  DOT_COLOR: "DOT_COLOR"
};
const TableActionsAlignment = {
  NONE: "NONE",
  LEFT: "LEFT",
  RIGHT: "RIGHT"
};
const VehicleInspectionStatus = {
  PASSED: "PASSED",
  FAILED: "FAILED"
};
const VehicleTaxStatus = {
  TAXED: "TAXED",
  UNTAXED: "UNTAXED"
};
const ValueType = {
  LICENSE: "LICENSE",
  GENDER: "GENDER",
  ETHNICITY: "ETHNICITY",
  VEHICLE: "VEHICLE",
  WEAPON: "WEAPON",
  BLOOD_GROUP: "BLOOD_GROUP",
  BUSINESS_ROLE: "BUSINESS_ROLE",
  CODES_10: "CODES_10",
  PENAL_CODE: "PENAL_CODE",
  DEPARTMENT: "DEPARTMENT",
  OFFICER_RANK: "OFFICER_RANK",
  DIVISION: "DIVISION",
  DRIVERSLICENSE_CATEGORY: "DRIVERSLICENSE_CATEGORY",
  IMPOUND_LOT: "IMPOUND_LOT",
  VEHICLE_FLAG: "VEHICLE_FLAG",
  CITIZEN_FLAG: "CITIZEN_FLAG",
  QUALIFICATION: "QUALIFICATION",
  CALL_TYPE: "CALL_TYPE",
  ADDRESS: "ADDRESS",
  EMERGENCY_VEHICLE: "EMERGENCY_VEHICLE",
  ADDRESS_FLAG: "ADDRESS_FLAG"
};
const ValueLicenseType = {
  LICENSE: "LICENSE",
  REGISTRATION_STATUS: "REGISTRATION_STATUS",
  INSURANCE_STATUS: "INSURANCE_STATUS"
};
const DepartmentType = {
  LEO: "LEO",
  EMS_FD: "EMS_FD"
};
const DriversLicenseCategoryType = {
  AUTOMOTIVE: "AUTOMOTIVE",
  AVIATION: "AVIATION",
  WATER: "WATER",
  FIREARM: "FIREARM"
};
const EmployeeAsEnum = {
  OWNER: "OWNER",
  MANAGER: "MANAGER",
  EMPLOYEE: "EMPLOYEE"
};
const QualificationValueType = {
  QUALIFICATION: "QUALIFICATION",
  AWARD: "AWARD"
};
const ShouldDoType = {
  SET_OFF_DUTY: "SET_OFF_DUTY",
  SET_ON_DUTY: "SET_ON_DUTY",
  SET_ASSIGNED: "SET_ASSIGNED",
  SET_STATUS: "SET_STATUS",
  PANIC_BUTTON: "PANIC_BUTTON",
  EN_ROUTE: "EN_ROUTE",
  ON_SCENE: "ON_SCENE",
  UNAVAILABLE: "UNAVAILABLE"
};
const StatusValueType = {
  STATUS_CODE: "STATUS_CODE",
  SITUATION_CODE: "SITUATION_CODE"
};
const WhatPages = {
  DISPATCH: "DISPATCH",
  EMS_FD: "EMS_FD",
  LEO: "LEO"
};
const BoloType = {
  VEHICLE: "VEHICLE",
  PERSON: "PERSON",
  OTHER: "OTHER"
};
const RecordType = {
  ARREST_REPORT: "ARREST_REPORT",
  TICKET: "TICKET",
  WRITTEN_WARNING: "WRITTEN_WARNING"
};
const ReleaseType = {
  TIME_OUT: "TIME_OUT",
  BAIL_POSTED: "BAIL_POSTED"
};
const WarrantStatus = {
  ACTIVE: "ACTIVE",
  INACTIVE: "INACTIVE"
};
const ExpungementRequestStatus = {
  ACCEPTED: "ACCEPTED",
  DENIED: "DENIED",
  PENDING: "PENDING",
  CANCELED: "CANCELED"
};
const LicenseExamPassType = {
  PASSED: "PASSED",
  FAILED: "FAILED"
};
const LicenseExamStatus = {
  IN_PROGRESS: "IN_PROGRESS",
  PASSED: "PASSED",
  FAILED: "FAILED"
};
const LicenseExamType = {
  DRIVER: "DRIVER",
  FIREARM: "FIREARM",
  WATER: "WATER",
  PILOT: "PILOT"
};
const CustomFieldCategory = {
  CITIZEN: "CITIZEN",
  WEAPON: "WEAPON",
  VEHICLE: "VEHICLE"
};
const PaymentStatus = {
  PAID: "PAID",
  UNPAID: "UNPAID"
};
const PenalCodeType = {
  INFRACTION: "INFRACTION",
  MISDEMEANOR: "MISDEMEANOR",
  FELONY: "FELONY"
};
const ActiveToneType = {
  LEO: "LEO",
  EMS_FD: "EMS_FD",
  SHARED: "SHARED"
};

export { ActiveToneType, BoloType, CustomFieldCategory, DepartmentType, DiscordWebhookType, DriversLicenseCategoryType, EmployeeAsEnum, ExpungementRequestStatus, Feature, JailTimeScale, LicenseExamPassType, LicenseExamStatus, LicenseExamType, PaymentStatus, PenalCodeType, QualificationValueType, Rank, RecordType, ReleaseType, ShouldDoType, StatusValueType, StatusViewMode, TableActionsAlignment, ValueLicenseType, ValueType, VehicleInspectionStatus, VehicleTaxStatus, WarrantStatus, WhatPages, WhitelistStatus };
